




























































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import faqQuestions from '~/const/faq'
import { sendEmailContactSupport } from '../api/email'

@Component
export default class HelpCenterPage extends Vue {
    contactForm = {
        name: this.$store.state.user.authenticatedUser ? this.$store.state.user.authenticatedUser.first_name : '',
        email: this.$store.state.user.authenticatedUser ? this.$store.state.user.authenticatedUser.email : '',
        message: ''
    }
    messageSent = false
    contactFromError = ''
    faqSearch = ''
    faqQuestions = faqQuestions

    get filteredQuestions() {
        // return 3 questions if search input is empty, otherwise
        if (this.faqSearch.length === 0) return this.faqQuestions.slice(0, 3)
        // filter both question title and question content => by user search input
        return this.faqQuestions.filter(
            element => element.title.match(new RegExp(this.faqSearch, 'i')) || element.content.match(new RegExp(this.faqSearch, 'i'))
        )
    }

    get contactFormIsEmpty(): boolean {
        return this.contactForm.name.length === 0 || this.contactForm.email.length === 0 || this.contactForm.message.length === 0
    }

    toggleAccordion(index: number) {
        const currentOpenedQuestion = this.filteredQuestions[index]
        this.filteredQuestions.forEach(question => {
            // if the click is on the current opened question
            // exit forEach and execute other code to toggle it (in this case it will close it)
            if (question === currentOpenedQuestion) return true
            // if the click is on a closed question, close the current active question
            // and execute other code to toggle clicked question (in this case it will open it)
            else if (question.isActive === true) question.isActive = !question.isActive
        })
        this.filteredQuestions[index].isActive = !this.filteredQuestions[index].isActive
    }

    async sendInquiryEmail() {
        if (this.contactFormIsEmpty) this.contactFromError = 'Please fill out the form above to submit your inquiry'
        else {
            await sendEmailContactSupport(this.contactForm)
            this.messageSent = true
        }
    }
}
