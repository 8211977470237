var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c("section", { staticClass: "section h-mt-100" }, [
      _c(
        "div",
        { staticClass: "flexbox flexbox--center h-mb-40" },
        [_c("icon", { staticClass: "logoIcon", attrs: { name: "logo-icon" } })],
        1
      ),
      _c("div", { staticClass: "container container--xs" }, [
        _c(
          "form",
          { staticClass: "form" },
          [
            _c("icon", {
              staticClass: "formInput__icon formInput__icon--lg",
              attrs: { name: "search" },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.faqSearch,
                  expression: "faqSearch",
                },
              ],
              staticClass:
                "formInput formInput--fullWidth formInput--lg formInput--rounded",
              attrs: { type: "text", placeholder: "Type your question here" },
              domProps: { value: _vm.faqSearch },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.faqSearch = $event.target.value
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "accordion" },
          [
            _vm.filteredQuestions.length === 0
              ? _c("h3", { staticClass: "h-textCenter" }, [
                  _vm._v("Nothing found"),
                ])
              : _vm._l(_vm.filteredQuestions, function (question, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "accordion__item",
                      class: { "accordion__item--open": question.isActive },
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "accordion__title",
                          on: {
                            click: function ($event) {
                              return _vm.toggleAccordion(index)
                            },
                          },
                        },
                        [
                          _vm._v(_vm._s(question.title)),
                          _c("icon", {
                            staticClass: "accordion__icon",
                            attrs: {
                              name: !question.isActive
                                ? "open-menu"
                                : "arrow-down",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "transition",
                        { attrs: { name: "accordionAnimation" } },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: question.isActive,
                                expression: "question.isActive",
                              },
                            ],
                            staticClass: "accordion__content",
                            domProps: { innerHTML: _vm._s(question.content) },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                }),
          ],
          2
        ),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container container--sm" },
        [
          _c(
            "router-link",
            {
              staticClass: "banner",
              attrs: { to: { name: "Signup" }, tag: "div" },
            },
            [
              _c("icon", {
                staticClass: "banner__icon",
                attrs: { name: "negotiation" },
              }),
              _c("div", [
                _c("h2", { staticClass: "banner__title" }, [
                  _vm._v("Want to sell your beats on TheCharts?"),
                ]),
                _c("p", { staticClass: "banner__subtitle" }, [
                  _vm._v(
                    "Sign up today and join other producers like you that sell beats and earn money on TheCharts."
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container container--xs" },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var handleSubmit = ref.handleSubmit
                  var invalid = ref.invalid
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form",
                        attrs: { autocomplete: "off", name: "contact" },
                      },
                      [
                        _c("div", { staticClass: "form__title" }, [
                          _c(
                            "div",
                            { staticClass: "flexbox flexbox--center" },
                            [
                              _c("icon", {
                                staticStyle: { "margin-right": "6px" },
                                attrs: { name: "email-help-o" },
                              }),
                              _c("h3", [
                                _vm._v(
                                  "Didn't find an answer to your question?"
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "p--lead lead--grey h-textCenter" },
                            [
                              _vm._v(
                                "Feel free to reach out with inquiries or to inform us about bugs"
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "formGroup formGroup--col2" },
                          [
                            _c(
                              "div",
                              { staticClass: "formGroup" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Name",
                                    rules: { required: true },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.contactForm.name,
                                                  expression:
                                                    "contactForm.name",
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              class: {
                                                hasError: errors.length > 0,
                                              },
                                              attrs: {
                                                name: "name",
                                                type: "text",
                                                placeholder: "Name",
                                                required: "",
                                              },
                                              domProps: {
                                                value: _vm.contactForm.name,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.contactForm,
                                                    "name",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]",
                                                  },
                                                ],
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [_vm._v("Name is required")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "formGroup" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Email",
                                    rules: { required: true, email: true },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.contactForm.email,
                                                  expression:
                                                    "contactForm.email",
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              class: {
                                                hasError: errors.length > 0,
                                              },
                                              attrs: {
                                                name: "email",
                                                type: "email",
                                                placeholder: "Email",
                                                required: "",
                                              },
                                              domProps: {
                                                value: _vm.contactForm.email,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.contactForm,
                                                    "email",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "formGroup" },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Message",
                                rules: { required: true },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.contactForm.message,
                                              expression: "contactForm.message",
                                            },
                                          ],
                                          staticClass:
                                            "formInput formInput--fullWidth",
                                          class: {
                                            hasError: errors.length > 0,
                                          },
                                          attrs: {
                                            name: "message",
                                            placeholder: "Message",
                                            required: "",
                                          },
                                          domProps: {
                                            value: _vm.contactForm.message,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.contactForm,
                                                "message",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]",
                                              },
                                            ],
                                            staticClass:
                                              "formInput__hint formInput__hint--error",
                                          },
                                          [_vm._v("Message can't be empty")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "formGroup" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.contactFromError,
                                  expression: "contactFromError",
                                },
                              ],
                              staticClass:
                                "formInput__hint formInput__hint--error formInput__hint formInput__hint--error--big",
                            },
                            [_vm._v(_vm._s(_vm.contactFromError))]
                          ),
                        ]),
                        _c("div", { staticClass: "form__submit" }, [
                          !_vm.messageSent
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn--fullWidth btn--primary btn--border",
                                  attrs: { type: "submit" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.sendInquiryEmail)
                                    },
                                  },
                                },
                                [_vm._v("SUBMIT")]
                              )
                            : _c("h3", { staticClass: "h-textCenter" }, [
                                _vm._v("Inquiry submitted"),
                              ]),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }