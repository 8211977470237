export default [
    {
        content: `When you see "Buy X Get X Free" on the producer's license, you can get a discount on your purchase,
            simply add all the beats before checking out and you\'ll see the price discount. For example with a "Buy 1 Get 2 Free",
            add 3 beats of the same license to your cart and you will only pay 1.`,
        title: 'How do I get the buy X get X free deal?',
        isActive: false
    },
    {
        content: `Yes. You can create a producer account in the <a href="/signup">Signup</a> page.`,
        title: 'Can I sell my beats on TheCharts?',
        isActive: false
    },
    {
        content: `If you've downloaded a beat and it has tags on it, it's because you have the <strong>demo download</strong>
            version, which you can use for writing and evaluating purposes.\n\nTo get the tagless beat you need to <strong>license</strong> it,
            you will then be able to legally use it for commercial or non-profit projects.`,
        title: 'The beat I got is tagged',
        isActive: false
    },
    {
        content: `From your <a href='/licenses'>Licenses</a> page, click on the file type you'd like to download and your beat will start the download.
            <em>Depending on the license you bought you will have different file formats available (MP3 / WAV / Trackout).</em>`,
        title: 'How do I download the beat I licensed?',
        isActive: false
    }
]
